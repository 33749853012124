import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Axios from "axios";
import MailIcon from "@material-ui/icons/Mail";
import "../style/loginModal.css";
import { Typography, SvgIcon } from "@material-ui/core";
import { useAppContext } from "../utils/hooks";

export default function LoginModal(props) {
  const [step, setStep] = useState(1);

  const { showError } = useAppContext();

  const [inputs, setInputs] = useState({
    emailOrNick: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const handleChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleKeyPress = (e, type) => {
    if (e.charCode === 13) {
      if (type === "login") handleLogin();
      else if (type === "signup") handleSignup();
      else if (type === "forgot") handleForgot();
    }
  };

  const handleSignup = e => {
    e.preventDefault();
    Axios.post(process.env.REACT_APP_API_ADDRESS + "/signup", inputs, {
      withCredentials: true
    })
      .then(res => {
        setStep(6);
      })
      .catch(err => {
        showError(err.response.data);
      });
  };

  const handleForgot = e => {
    e.preventDefault();
    Axios.post(process.env.REACT_APP_API_ADDRESS + "/forgot", inputs, {
      withCredentials: true
    })
      .then(res => {
        setStep(5);
      })
      .catch(err => {
        showError(err.response.data);
      });
  };

  const handleLogin = e => {
    Axios.post(process.env.REACT_APP_API_ADDRESS + "/login", inputs, {
      withCredentials: true
    })
      .then(res => {
        props.setLoggedIn(true);
        props.setUser(res.data);
        sessionStorage.setItem("user", JSON.stringify(res.data));
        props.show(false);
      })
      .catch(err => {
        showError(err.response.data);
      });
  };
  const showContent = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <Typography variant="h4">Prihláste sa:</Typography>
            <Button
              disabled={true}
              className="loginButton"
              id="fbButton"
              color="primary"
              variant="contained"
              onClick={() => (window.location = "/auth/facebook")}
            >
              <SvgIcon viewBox="0 0 100 100">
                <path
                  d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998   C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41   h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z"
                  fill="#FFFFFF"
                />
              </SvgIcon>
              Prihlásiť sa cez Facebook
            </Button>
            <Button
              disabled={true}
              className="loginButton"
              color="primary"
              variant="contained"
              onClick={() => (window.location = "/auth/google")}
            >
              <SvgIcon viewBox="0 0 50 50">
                <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z " />
              </SvgIcon>
              Prihlásiť sa cez Google
            </Button>
            <Button
              className="loginButton"
              variant="contained"
              color="primary"
              onClick={() => setStep(2)}
            >
              <MailIcon />
              Prihlásiť sa cez e-mail/nick
            </Button>
            <br />
            <Button onClick={() => props.show(false)}>Naspäť</Button>
          </div>
        );
      case 2:
        return (
          <div>
            <FormControl>
              <FormGroup controlId="formBasicEmail">
                <TextField
                  onKeyPress={e => handleKeyPress(e, "login")}
                  onChange={handleChange}
                  name="emailOrNick"
                  placeholder="E-mail/Nick"
                />
              </FormGroup>

              <FormGroup controlId="formBasicPassword">
                <TextField
                  onKeyPress={e => handleKeyPress(e, "login")}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  placeholder="Heslo"
                />
              </FormGroup>
              <Button variant="contained" onClick={handleLogin}>
                Odoslať
              </Button>
              <Button color="primary" onClick={() => setStep(3)}>
                Nemáte účet? Zaregistrujte sa!
              </Button>
              <Button onClick={() => setStep(4)}>Zabudnuté heslo</Button>
              <Button onClick={() => setStep(1)}>Naspäť</Button>
            </FormControl>
          </div>
        );
      case 3:
        return (
          <div>
            <FormControl className="loginForm">
              <FormGroup>
                <TextField
                  onKeyPress={() => handleKeyPress("signup")}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                />

                <TextField
                  onKeyPress={() => handleKeyPress("signup")}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  placeholder="Heslo"
                />

                <TextField
                  onKeyPress={() => handleKeyPress("signup")}
                  onChange={handleChange}
                  name="confirmPassword"
                  type="password"
                  placeholder="Potvrdiť heslo"
                />
              </FormGroup>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSignup}
              >
                Odoslať
              </Button>
              <Button onClick={() => setStep(2)}>Naspäť</Button>
            </FormControl>
          </div>
        );
      case 4:
        return (
          <div>
            <FormControl onSubmit={handleForgot}>
              <FormGroup controlId="formBasicEmail">
                <TextField
                  onKeyPress={() => handleKeyPress("forgot")}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                />
              </FormGroup>
              <Button
                onClick={handleForgot}
                variant="contained"
                color="primary"
                type="submit"
              >
                Odoslať
              </Button>
              <FormGroup>
                <Button onClick={() => setStep(1)}>Naspäť</Button>
              </FormGroup>
            </FormControl>
          </div>
        );
      case 5:
        return (
          <div>
            <Typography variant="body1">
              Informácie týkajuce sa obnovenia hesla boli poslané na Váš e-mail.
            </Typography>
            <Button onClick={() => setStep(1)}>Naspäť</Button>
          </div>
        );
      case 6:
        return (
          <div>
            <Typography variant="body1">
              Poslali sme vám potvrdzovací e-mail na {inputs.email}. Pre
              dokončenie registrácie kliknite na link umiestnený v ňom.
            </Typography>
            <Button onClick={() => setStep(1)}>Naspäť</Button>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div role="presentation">
      <div className="loginForm">{showContent()}</div>
    </div>
  );
}
