import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
let theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      h4: {
        fontSize: "24px",
        fontWeight: "bold"
      },
      h5: {
        fontWeight: "bold",
        textTransform: "uppercase"
      },
      h6: {
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: "14px"
      },
      body1: {
        fontWeight: "bold",
        fontSize: "14px"
      },
      colorTextSecondary: {
        color: "#FFF6"
      }
    },
    MuiMobileStepper: {
      dots: {
        margin: "0 auto"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none"
      },
      containedPrimary: {
        color: "#fff"
      }
    }
  },
  typography: {
    // Use the system font.
    fontFamily: "Ubuntu"
  },
  palette: {
    type: "dark",
    primary: {
      main: "#ff9a0a"
    },
    secondary: {
      main: "#fff",
      dark: "#808080"
    },
    tertiary: {
      main: "#808080"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#121212",
      elevated1: "#191919",
      elevated2: "#1d1d1d"
    }
  }
});

export default theme;
