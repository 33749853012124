import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import { useAppContext } from "../utils/hooks";
import Axios from "axios";
import NickModal from "./NickModal";
import "../style/navbar.css";
import WEBPlogo from "../assets/logo.webp";
import PNGlogo from "../assets/logo.png";
import {
  ButtonBase,
  Typography,
  IconButton,
  Button,
  Icon,
  Tabs,
  Tab
} from "@material-ui/core";
import BurgerIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import PeopleIcon from "@material-ui/icons/People";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ForumIcon from "@material-ui/icons/Forum";
import InfoIcon from "@material-ui/icons/Info";
import HelpIcon from "@material-ui/icons/Help";
import { useHistory } from "react-router";
import Askbutton from "./Askbutton";
import Image from "react-image-webp";
import AllInboxIcon from "@material-ui/icons/AllInbox";

const routes = [
  {
    to: "/articles",
    label: "Články",
    icon: <SpeakerNotesIcon color="primary" />
  },
  {
    to: "/products",
    label: "Produkty",
    icon: <AllInboxIcon color="primary" />
  },
  {
    to: "/calendar",
    label: "Turnaje",
    icon: (
      <Icon
        className="fas fa-trophy"
        style={{ transform: "scale(0.8)", overflow: "initial" }}
        color="primary"
      />
    )
  },
  {
    to: "/marketplace",
    label: "Trhovisko",
    icon: <ForumIcon color="primary" />
  },
  { to: "/beginner", label: "Ako začať?", icon: <HelpIcon color="primary" /> },
  { to: "/shops", label: "Herne", icon: <PeopleIcon color="primary" /> },
  { to: "/about", label: "O nás", icon: <InfoIcon color="primary" /> }
];

export default function Navbar(props) {
  const {
    user,
    loggedIn,
    setLoggedIn,
    setUser,
    showLoginMenu,
    toggleLoginMenu
  } = useAppContext();

  const [showNavMenu, toggleNavMenu] = useState(false);

  const [showNickMenu, toggleNickMenu] = useState(false);

  let history = useHistory();

  let initialTab = false;
  routes.map((route, index) => {
    if (history.location.pathname === route.to) {
      initialTab = index;
    }
    return null;
  });

  const [activeTab, setActiveTab] = useState(initialTab);

  const logOut = () => {
    Axios.get(process.env.REACT_APP_API_ADDRESS + "/logout", {
      withCredentials: true
    }).then(res => {
      if (res.status === 200) {
        sessionStorage.removeItem("user");
        setLoggedIn(false);
      }
    });
  };

  function allyProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      key: `simple-tab-${index}`
    };
  }

  const checkNickname = () => {
    if (user.email && !user.nickname && loggedIn) {
      if (!showNickMenu) {
        toggleNickMenu(true);
      }
    }
  };

  const NavMenu = () => (
    <div
      role="presentation"
      onClick={() => toggleNavMenu(false)}
      onKeyDown={() => toggleNavMenu(false)}
    >
      <div className="drawerNav">
        <List>
          {routes.map(route => (
            <ListItem disabled={route.disabled} button key={route.label}>
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <NavLink to={route.to} key={route.to}>
                    {route.label}
                  </NavLink>
                }
              />
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    </div>
  );

  const links = routes.map(({ to, label, disabled }, index) => {
    //console.log(label, index);
    if (disabled) {
      return <Tab label={label} disabled={true} {...allyProps(index)} />;
    } else {
      return (
        <Tab
          onClick={() => history.push(to)}
          label={label}
          {...allyProps(index)}
        />
      );
    }
  });

  return (
    <nav className="topnav">
      <div id="navcontainer">
        <div className="burger">
          <IconButton
            color="secondary"
            aria-label="open drawer"
            size="medium"
            onClick={() => toggleNavMenu(true)}
          >
            <BurgerIcon />
          </IconButton>
        </div>

        <NavLink
          onClick={() => setActiveTab(false)}
          exact
          to="/"
          key="/"
          className="logo"
        >
          <Image src={PNGlogo} webp={WEBPlogo} alt="MtG.sk" />
        </NavLink>

        <div className="navlinks">
          <Tabs
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            value={activeTab}
            aria-label="simple tabs example"
          >
            {links}
          </Tabs>
        </div>
        {loggedIn ? (
          <div className="login">
            <div>
              <ButtonBase>
                <Link
                  onClick={() => setActiveTab(false)}
                  to="/user"
                  style={{ float: "right" }}
                  href="#login"
                >
                  <Typography variant="body1">{user.nickname}</Typography>
                </Link>
              </ButtonBase>
              <IconButton onClick={logOut}>
                <LogoutIcon />
              </IconButton>
              {checkNickname()}
            </div>
          </div>
        ) : (
          <div className="login">
            <div>
              <IconButton
                className="mobileOnly"
                onClick={() => toggleLoginMenu(true)}
              >
                <AccountCircle color="secondary" />
              </IconButton>

              <Button
                variant="outlined"
                className="desktopOnly"
                color="primary"
                onClick={toggleLoginMenu}
              >
                Prihlásiť sa
              </Button>
            </div>
          </div>
        )}

        <SwipeableDrawer
          anchor="left"
          open={showNavMenu}
          onClose={() => toggleNavMenu(false)}
          onOpen={() => toggleNavMenu(true)}
        >
          {NavMenu("right")}
        </SwipeableDrawer>

        <Drawer
          anchor="top"
          open={showLoginMenu}
          onClose={() => toggleLoginMenu(false)}
          onOpen={() => toggleLoginMenu(true)}
        >
          <LoginModal
            show={toggleLoginMenu}
            setUser={setUser}
            user={user}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
          />
        </Drawer>

        <Drawer
          anchor="top"
          open={showNickMenu}
          onClose={() => toggleNickMenu(false)}
          onOpen={() => toggleNickMenu(true)}
        >
          <NickModal
            show={toggleNickMenu}
            setUser={setUser}
            user={user}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
          />
        </Drawer>
      </div>
      <div
        onClick={() => {
          setActiveTab(false);
        }}
      >
        <Askbutton />
      </div>
    </nav>
  );
}
