import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import { useAppContext } from "../utils/hooks";
import Axios from "axios";

export default function NickModal(props) {
  const [inputs, setInputs] = useState({
    nickname: ""
  });

  const { showError } = useAppContext();
  const handleChange = e => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    Axios.post(process.env.REACT_APP_API_ADDRESS + "/account/nickname", inputs, {
      withCredentials: true
    })
      .then(res => {
        props.setUser(res.data);
        sessionStorage.setItem("user", JSON.stringify(res.data));
        props.show(false);
      })
      .catch(err => {
        showError(err.response.data);
      });
  };

  const showContent = () => {
    return (
      <FormControl>
        <FormGroup>
          <TextField
            label="Zvoľte si nickname."
            onChange={handleChange}
            name="nickname"
            type="text"
            placeholder="Nickname"
          />
        </FormGroup>

        <Button onClick={handleSubmit} variant="primary" type="submit">
          Potvrdiť
        </Button>
      </FormControl>
    );
  };

  return (
    <div role="presentation">
      <div className="loginForm">{showContent()}</div>
    </div>
  );
}
