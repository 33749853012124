import React from "react";
import "../style/footer.css";
import { Link } from "react-router-dom";
import { Divider, Typography, List, ListItem } from "@material-ui/core";
import FBlogo from "../assets/images/FBlogo.png";
import { useAppContext } from "../utils/hooks";
export default function Footer() {
  const { user } = useAppContext();

  const navigation = () => {
    const nav = [
      { name: "Domov", link: "/" },
      { name: "Články", link: "/articles" },
      { name: "Produkty", link: "/products" },
      { name: "Turnaje", link: "/calendar" },
      { name: "Trhovisko", link: "/marketplace" },
      { name: "Ako začať", link: "/beginner", disabled: true },
      { name: "Herne", link: "/shops" },
      { name: "O nás", link: "/about" },
      { name: "Spoilery", link: "/spoilers" }
    ];

    return (nav.map(item => {
      return (
        <ListItem key={item.name}>
          <Link disabled={item.disabled} to={item.link}>
            {item.name}
          </Link>
        </ListItem>
      );
    }));
  };
  return (
    <footer>
      <Divider />

      <div>
        <Typography variant="h6">Navigácia</Typography>
        <List>{navigation()}</List>
        {user.admin ? (
          <ListItem>
            <Link to="/admin">Admin</Link>
          </ListItem>
        ) : null}
      </div>

      <div>
        <Typography variant="h6">Informácie</Typography>
        <List>
          <ListItem>
            <Link to="/">Ochrana osobných údajov</Link>
          </ListItem>
          <ListItem>
            <Link to="/">Obchodné podmienky</Link>
          </ListItem>
        </List>
      </div>

    

      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/MtG.sk/"
        >
          <img alt="FB" height="24px" src={FBlogo} />
        </a>
      </div>
      <span style={{marginBottom: '10px'}}>
        v{process.env.REACT_APP_VERSION}
      </span>
    </footer>
  );
}
