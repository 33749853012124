import React from "react";
import { Link } from "react-router-dom";
import "../style/askbutton.css";

export default function Askbutton() {
  return (
    <div className="askButton">
      <Link to="/questions">
        Otázky a odpovede{" "}
        <i className="fa fa-question-circle" aria-hidden="true"></i>
      </Link>
    </div>
  );
}
