import { useState, useEffect } from "react";
import { useContext } from "react";
import Context from "./context";

function openIndexedDB(fileindex) {
  // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
  var indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;

  var openDB = indexedDB.open("MyDatabase", 1);

  openDB.onupgradeneeded = function() {
    var db = {};
    db.result = openDB.result;
    db.store = db.result.createObjectStore("MyObjectStore", { keyPath: "id" });
    if (fileindex) db.index = db.store.createIndex("NameIndex", fileindex);
  };

  return openDB;
}

function getStoreIndexedDB(openDB) {
  var db = {};
  db.result = openDB.result;
  db.tx = db.result.transaction("MyObjectStore", "readwrite");
  db.store = db.tx.objectStore("MyObjectStore");

  return db;
}

function saveIndexedDB(filename, filedata, fileindex) {
  var openDB = openIndexedDB(fileindex);

  openDB.onsuccess = function() {
    var db = getStoreIndexedDB(openDB);
    if (filename.includes("tournaments?date")) filename = "tournaments";
    if (filename.includes("competitions?date")) filename = "competitions";
    if (filename.includes("articles")) filename = "articles";

    db.store.put({ id: filename, data: filedata });
  };

  return true;
}

function loadIndexedDB(filename, callback, filesearch) {
  var openDB = openIndexedDB();

  openDB.onsuccess = function() {
    var db = getStoreIndexedDB(openDB);
    if (filename.includes("tournaments?date")) filename = "tournaments";
    if (filename.includes("competitions?date")) filename = "competitions";
    if (filename.includes("articles")) filename = "articles";
    var getData;
    if (filename) {
      getData = db.store.get(filename);
    } else {
      getData = db.index.get(filesearch);
    }

    getData.onsuccess = function() {
      callback(getData.result.data);
    };

    db.tx.oncomplete = function() {
      db.result.close();
    };
  };

  return true;
}

export const useAppContext = () => {
  return useContext(Context);
};

export const useFetch = (url, initialValue) => {
  const [result, setResult] = useState(initialValue);

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(json => {
        setResult(json);
        if (process.env.NODE_ENV === "production") saveIndexedDB(url, json);
      })
      .catch(err => {
        if (process.env.NODE_ENV === "production")
          loadIndexedDB(url, record => setResult(record));
      });
  }, []); //Putting url here causes infinite rerender
  return result;
};

export const useFetchRepeat = (url, initialValue, condition) => {
  const [result, setResult] = useState(initialValue);

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(json => {
        setResult(json);
        if (process.env.NODE_ENV === "production") saveIndexedDB(url, json);
      })
      .catch(err => {
        if (process.env.NODE_ENV === "production")
          loadIndexedDB(url, record => setResult(record));
      });
  }, [...condition, url]);
  return [result, setResult];
};
