import React, { useState, useEffect } from "react";
import Context from "./utils/context";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Axios from "axios";
import "./style/app.css";
import Footer from "./Includes/Footer";
import Navbar from "./Includes/Navbar";
import Helmet from "react-helmet";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./style/utilities/theme";
import ScrollToTop from "./utils/ScrollToTop";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/en-GB";
import SnackBars from "./Includes/SnackBars";
import asyncComponent from "./utils/AsyncComponent";

const FrontPage = asyncComponent(() => import("./SimplePages/FrontPage"));
const Article = asyncComponent(() => import("./Article/Article"));
const ArticlePage = asyncComponent(() => import("./Article/ArticlePage"));
const AccountPage = asyncComponent(() => import("./Account/AccountPage"));
const SpoilerPage = asyncComponent(() => import("./SimplePages/SpoilersPage"));
const ResetPage = asyncComponent(() => import("./SimplePages/ResetPage"));
const AdminPage = asyncComponent(() => import("./Admin/AdminPage"));
const AboutPage = asyncComponent(() => import("./SimplePages/AboutPage"));
const ProductPage = asyncComponent(() => import("./SimplePages/ProductPage"));
const BeginnerPage = asyncComponent(() => import("./SimplePages/BeginnerPage"));
const Tournament = asyncComponent(() => import("./Events/Tournament"));
const Competition = asyncComponent(() => import("./Events/Competition"));
const CalendarPage = asyncComponent(() => import("./SimplePages/CalendarPage"));
const Questions = asyncComponent(() => import("./Questions/Questions"));
const MarketplacePage = asyncComponent(() =>
  import("./Marketplace/MarketplacePage")
);
const ShopsPage = asyncComponent(() => import("./SimplePages/ShopsPage"));
const ConfirmPage = asyncComponent(() => import("./SimplePages/ConfirmPage"));
const NotFoundPage = asyncComponent(() => import("./SimplePages/NotFoundPage"));


function App() {
  const [user, setUser] = useState({ profile: {} });
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoginMenu, toggleLoginMenu] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    msg: "",
    variant: "error"
  });

  const showError = message => {
    setSnack({open: true, msg: message, variant: 'error'});
  }

  const showSuccess = message => {
    setSnack({open: true, msg: message, variant: 'success'})
  }


  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      //fix banska bystrica bug - if the user i set is incorrect format, dont use it
      //seriously just rework this
      let userFromCache = JSON.parse(sessionStorage.getItem("user"));
      if(userFromCache.nickname){
        setUser(userFromCache);
        setLoggedIn(true);
      }else{
        sessionStorage.removeItem("user");
      }
    }

    Axios.get(process.env.REACT_APP_API_ADDRESS + "/account", {
      withCredentials: true
    })
      .then(res => {
        if (res.status === 200) {
          setLoggedIn(true);
          setUser(res.data);
          sessionStorage.setItem("user", JSON.stringify(res.data));
        }
      })
      .catch(err => {

      });

    Axios.get(process.env.REACT_APP_API_ADDRESS+'/version')
    .then(res => {
      if(res.data.version !== process.env.REACT_APP_VERSION){ 
        if(window.caches){
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
          window.location.reload(true);
        }
      }
    })
  }, [loggedIn]);

  return (
    <Context.Provider
      value={{
        user,
        loggedIn,
        setLoggedIn,
        setUser,
        showLoginMenu,
        toggleLoginMenu,
        showSuccess,
        showError
      }}
    >
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
          <Helmet>
            <title>MtG.sk</title>
            <meta name="description" content="frontpage" />
          </Helmet>
          <BrowserRouter>
            <ScrollToTop />
            <div className="app">
              <Navbar />

              <Route
                render={({ location }) => {
                  return (
                    <div className="view">
                      <Switch location={location}>
                        <Route
                          path="/"
                          render={props => <FrontPage {...props} />}
                          exact
                        />
                        <Route
                          path="/article/:articleTitle"
                          render={props => <Article {...props} />}
                        />
                        <Route
                          path="/articles"
                          render={props => <ArticlePage {...props} />}
                        />
                        <Route
                          path="/user"
                          render={props => <AccountPage {...props} />}
                        />
                        <Route
                          path="/calendar"
                          render={props => <CalendarPage {...props} />}
                        />
                        <Route
                          path="/competition/:tournamentID"
                          render={props => <Competition {...props} />}
                        />
                        <Route
                          path="/tournament/:tournamentID"
                          render={props => <Tournament {...props} />}
                        />
                        <Route
                          path="/questions"
                          render={props => <Questions {...props} />}
                        />
                        <Route
                          path="/marketplace"
                          render={props => <MarketplacePage {...props} />}
                        />
                        <Route
                          path="/shops"
                          render={props => <ShopsPage {...props} />}
                        />
                        <Route
                          path="/spoilers"
                          render={props => <SpoilerPage {...props} />}
                        />
                        <Route
                          path="/reset"
                          render={props => <ResetPage {...props} />}
                        />
                        <Route
                          path="/about"
                          render={props => <AboutPage {...props} />}
                        />
                        <Route
                          path="/admin"
                          render={props => <AdminPage {...props} />}
                        />
                        <Route
                          path="/products"
                          render={props => <ProductPage {...props} />}
                        />
                        <Route
                          path="/beginner"
                          render={props => <BeginnerPage {...props} />}
                        />
                        <Route
                          path="/activate"
                          render={props => <ConfirmPage {...props} />}
                        />

                        <Route render={props => <NotFoundPage {...props} />} />
                      </Switch>
                    </div>
                  );
                }}
              />

              <Footer />

              <SnackBars
                variant={snack.variant}
                message={snack.msg}
                open={snack.open}
                hide={() => setSnack({ ...snack, open: false })}
              />
            </div>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
